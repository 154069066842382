import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Typography } from '@material-ui/core';
import { ContentWrapper, ProductHuntWrapper, ProductHuntLink } from './styles';
import Button from '../../../../components/Common/Button';
import Logo from '../../../../assets/img/Common/logo-with-text.png';
import ProductHuntLogo from '../../../../assets/img/Partners/product-hunt.svg';

const carouselContent = [
  {
    title: 'Earn with Portfolios',
    description:
      'Engage in public portfolio performance competitions to earn tokens and gain recognition.',
  },
  {
    title: 'Discover Portfolios',
    description:
      'Follow public portfolio strategies to uncover new investment opportunities across various asset classes.',
  },
  {
    title: 'Manage Portfolios',
    description:
      'Get insights to manage your portfolios effectively independently from banks and brokers.',
  },
];
const Content = () => {
  return (
    <ContentWrapper className="d-flex flex-column justify-content-center h-100">
      <Row className="position-relative justify-content-center">
        <Col md={12} className="mb-5">
          <Row className="justify-content-center">
            <Col md={3}>
              <Fade top cascade>
                <div className="wrappLogo mb-5">
                  <img src={Logo} alt="" className="img-fluid" />
                </div>
              </Fade>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={9}>
              <div className="d-flex h-100">
                <Carousel interval={5000} controls={false}>
                  {carouselContent.map((item) => (
                    <Carousel.Item>
                      <h1>{item.title}</h1>
                      <p>{item.description}</p>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12} justify="center" className="d-flex justify-content-center">
          <Fade bottom cascade>
            <div className="d-flex h-100 align-items-center">
              {/* <Button href="/defi" title="Stake" fullwidith mb3>
                Stake
              </Button>
              <Button
                href="https://quipuswap.com/swap"
                title="Buy $EASY"
                target="_blank"
                fullwidith
                mb3
              >
                Buy $EASY
              </Button>
              <Button
                href="https://github.com/cryptoeasy/whitepaper"
                title="Whitepaper"
                target="_blank"
                fullwidith
                mb3
              >
                Whitepaper
              </Button> */}
              <Button
                href="https://app.divercefi.com"
                target="_blank"
                title="Join Future of Investing"
                fullwidith
                mb3
                className="mb-0"
              >
                Access App
              </Button>
            </div>
          </Fade>
        </Col>
        {/* <ProductHuntWrapper>
          <Typography style={{ color: 'white' }}>Featured on</Typography>
          <ProductHuntLink
            href="https://www.producthunt.com/products/divercefi"
            target="_blank"
            rel="noreferrer"
          >
            <img src={ProductHuntLogo} alt="product hunt" height="100%" />
          </ProductHuntLink>
        </ProductHuntWrapper> */}
      </Row>
    </ContentWrapper>
  );
};

export default Content;
