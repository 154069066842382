import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useModal } from 'react-modal-hook';
import { Button, Dialog } from '@material-ui/core';
import { CloseOutlined } from '@ant-design/icons';
// import ReactFullpage from '@fullpage/react-fullpage';

import { WrappHomePage } from './styles';
import SubscribeForm from './components/SubscribeForm';
import TopSection from './components/TopSection';
import PortfolioSection from './components/Portfolio';
import SolutionsSection from './components/SolutionsSection';
// import TechnologySection from './components/Technology';
import PartnersSection from './components/Partners';
import Footer from '../../components/Footer';
import TopMenu from '../../components/TopMenu';
import FixedMenuWrapper from '../../components/FixedMenuWrapper';
import { GeneralWrapp } from '../../components/Common/GeneralWrapp';

const HomePage = () => {
  const [imgToShow, setImgToShow] = useState('');
  // const [showSubscribe, setShowSubscribe] = useState(false);
  const [isMobile, setIsMobile] = useState();

  const [showModal, hideModal] = useModal(
    ({ in: open, onExited }) => (
      <Dialog
        maxWidth="xl"
        fullScreen
        open={open}
        onExited={onExited}
        onClose={hideModal}
      >
        <Button onClick={hideModal} className="closeBtn">
          <CloseOutlined />
        </Button>
        <img src={imgToShow} alt="" className="modalImg" />
      </Dialog>
    ),
    [imgToShow]
  );

  const imgClickHandler = (e) => {
    showModal();
    setImgToShow(e.target.src);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowSubscribe(true);
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, []);

  // const handleCloseSubscribe = () => {
  //   setShowSubscribe(false);
  // };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, [window]);

  return (
    <div>
      <Helmet>
        <title>Divercefi - Your ultimate investment management solution</title>
        <meta
          name="description"
          content="Discover investment opportunities, follow best strategies, build diversified portfolios, keep track of- and manage all your investments, leverage DeFi"
        />
        <link rel="canonical" href="https://divercefi.com/" />

        {/* Facebook */}
        <meta
          property="og:title"
          content="Divercefi - Your ultimate investment management solution"
        />
        <meta
          property="og:description"
          content="Discover investment opportunities, follow best strategies, build diversified portfolios, keep track of- and manage all your investments, leverage DeFi"
        />
        <meta
          property="og:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta property="og:url" content="https://divercefi.com" />

        {/* Twitter */}
        <meta
          name="twitter:title"
          content="Divercefi - Your ultimate investment management solution"
        />
        <meta
          name="twitter:description"
          content="Discover investment opportunities, follow best strategies, build diversified portfolios, keep track of- and manage all your investments, leverage DeFi"
        />
        <meta
          name="twitter:image"
          content="https://divercefi.com/img/logo-1200x627.png"
        />
        <meta name="twitter:card" content="https://divercefi.com" />
      </Helmet>

      <WrappHomePage>
        <FixedMenuWrapper>
          <GeneralWrapp className="flex-grow-1">
            <TopMenu />
          </GeneralWrapp>
        </FixedMenuWrapper>
        <>
          <div className="section">
            <TopSection id="section1" />
          </div>
          <div className="section">
            <PortfolioSection id="section2" />
          </div>
          <div className="section">
            <SolutionsSection id="section3" imgClickHandler={imgClickHandler} />
          </div>
          {/* <div className="section">
              <TechnologySection id="section4" />
            </div> */}
          {/* <div className="section">
              <Overview
                id="section3"
                imgClickHandler={imgClickHandler}
              />
            </div> */}
          {/* <div className="section" id="subscribe">
            <SubscribeForm id="section5" />
          </div>
          <div className="section">
            <PartnersSection id="section6" />
          </div> */}
          <Footer />
        </>
      </WrappHomePage>
    </div>
  );
};

export default HomePage;
