import React from 'react';
import { Row, Col, Image, Carousel } from 'react-bootstrap';
import { Link } from '@material-ui/core';
import { ContentWrapper } from './styles';
import { GeneralWrapp } from '../../../../components/Common/GeneralWrapp';
import photo1 from '../../../../assets/img/Solutions/portfolio-building1.png';
import photo2 from '../../../../assets/img/Solutions/portfolio-building2.png';
import photo3 from '../../../../assets/img/Solutions/portfolio-tracking1.png';
import photo4 from '../../../../assets/img/Solutions/portfolio-tracking2.png';

const carouselContent = [
  {
    title: 'For investors',
    subtitle: 'Your trusted interface to plan and manage your investor journey',
    description: [
      'Educational content on wealth management',
      'Portfolio building and planning from scratch',
      'More than 5000 assets from 4 asset classes',
      'Portfolio tracking with backtesting and aggregated views',
      'Wallet and exchange acount integration',
    ],
    image1: photo1,
    image2: photo2,
  },
  {
    title: 'For community portfolio managers',
    subtitle: 'Manage portfolios for the community and get rewarded for it',
    description: [
      'Publish your portfolios strategy and build a community of investment followers',
      'Show your performance and build a track record',
      'Earn tokens based on your followers performance',
      'Redirect and connect your followers to best investment platforms',
    ],
    image1: photo3,
    image2: photo4,
  },
];

const Content = () => {
  return (
    <ContentWrapper className="d-flex flex-column justify-content-center">
      <GeneralWrapp className="position-relative">
        <Row className="justify-content-center">
          <Col md={12}>
            <div className="d-flex flex-column gap-5">
              {/* <Carousel interval={5000}> */}
              {carouselContent.map((item, idx) => (
                <div className="mb-5">
                  <h1 className="mb-3">{item.title}</h1>
                  <h3 className="mb-5">{item.subtitle}</h3>
                  <Row className="content-grid align-items-center">
                    <Col md={{ span: 7, order: idx % 2 === 0 ? 1 : 2 }}>
                      <div
                        className={`position-relative images text-${
                          idx % 2 === 0 ? 'start' : 'end'
                        }`}
                      >
                        <Image
                          fluid
                          src={item.image1}
                          className="shadow-lg abs__img--0"
                        />
                        <Image
                          fluid
                          src={item.image2}
                          className={`position-absolute abs__img--1 ${
                            idx % 2 === 0 ? 'left' : 'right'
                          }`}
                        />
                      </div>
                    </Col>
                    <Col md={{ span: 5, order: idx % 2 === 0 ? 2 : 1 }}>
                      <ul>
                        {item.description.map((el, index) => (
                          <li>
                            {el}
                            {' '}
                            {/* {index === 3 && idx === 1 ? (
                              <Link href="/#subscribe" className="customLink">
                                (Apply Below)
                              </Link>
                            ) : null} */}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </div>
              ))}
              {/* </Carousel> */}
            </div>
          </Col>
        </Row>
        {/* <Row className="justify-content-center h-100">
          <Col md={4} xs={12} justify="center" className="persona__wrapp">
            <div className="persona d-flex flex-column">
              <h2 className="">INVESTORS</h2>
              <h3>
                &quot;Your trusted interface to invest in crunte and hexand&quot;
              </h3>
              <div className="images">
                <Fade left cascade>
                  <Image fluid src={photo1} className="w-75" />
                </Fade>
                <div className="position-relative">
                  <Fade right cascade>
                    <Image fluid src={photo2} className="w-50 position-absolute abs__img--1" />
                  </Fade>
                </div>
                <Fade bottom cascade>
                  <Image fluid src={photo3} className="w-50 p-3" />
                </Fade>
              </div>
            </div>
          </Col>
          <Col md={4} xs={12} justify="center" className="persona__wrapp">
            <div className="persona d-flex flex-column-reverse">
              <h2 className="">
                Financial leaders/ experts and asset managers
              </h2>
              <h3>
                &quot;Mint portfolio NFTs, Manage Portfolios, earn $EASY tokens, connect brokers for your community, share your performances&quot;
              </h3>
              <div className="images">
                <Fade top cascade>
                  <Image fluid src={photo4} className="w-75" />
                </Fade>
                <div className="position-relative">
                  <Fade right cascade>
                    <Image fluid src={photo5} className="w-50 position-absolute abs__img--1" />
                  </Fade>
                </div>
                <Fade bottom cascade>
                  <Image fluid src={photo6} className="w-50 p-3" />
                </Fade>
              </div>
            </div>
          </Col>
          <Col md={4} xs={12} justify="center" className="persona__wrapp">
            <div className="persona d-flex flex-column">
              <h2 className="">
                Brokers, exchanges, and custody solutions
              </h2>
              <h3>
                &quot;Generate AUM and transaction volume, support exposure for listed portfolios, integrate with our ecocsystem, get verified KYC data from, leverage our interface&quot;
              </h3>
              <div className="images">
                <Fade left cascade>
                  <Image fluid src={photo7} className="w-75" />
                </Fade>
                <div className="position-relative">
                  <Fade right cascade>
                    <Image fluid src={photo8} className="w-50 position-absolute abs__img--3" />
                  </Fade>
                </div>
                <Fade bottom cascade>
                  <Image fluid src={photo9} className="w-50 p-3" />
                </Fade>
              </div>
            </div>
          </Col>
        </Row> */}
      </GeneralWrapp>
    </ContentWrapper>
  );
};

export default Content;
